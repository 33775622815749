<template>
  <div class="container-fluid">
    <header class="main-header">
      <h1>
        Strumento di autovalutazione in tema di <span>Salute, Sicurezza sul Lavoro e Differenze di Genere - Versione 2.0</span>
      </h1>
      <div class="row menu">
        <div class="col-1">
          <a v-if="!hideMenuToggle" class="btn" @click.prevent="$emit('toggle-menu-open')">
            {{ mainMenuOpen ? ' ' : ' ' }}
          </a>
        </div>
        <div class="col-xs-11 company-name" v-if="showCompanyName && companyName">{{ companyName }} {{ referenceYear }}</div>
      </div>
    </header>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  props: {
    showCompanyName: {
      type: Boolean,
      default: false,
    },
    mainMenuOpen: {
      type: Boolean,
      default: false,
    },
    hideMenuToggle: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('survey', {
      companyName: 'companyName',
      referenceYear: 'referenceYear',
    }),
    surveyData () {
      return {};
    },
  },
};

</script>

<style lang="scss">
</style>
